<template>
  <div>
    <sync-histories />
  </div>
</template>

<script>
import SyncHistories from '@/components/InternalRecords/SyncHistories.vue'

export default {
  components: { SyncHistories },
  name: 'SyncHistory',
}
</script>
